@font-face {
  font-family: 'Frutiger Roman';
  src: url("../fonts/FrutigerRoman.otf");
  src: url("../fonts/frutiger_lt_std_roman.eot");
  src: url("../fonts/frutiger_lt_std_roman.eot?#iefix") format("embedded-opentype"), url("../fonts/frutiger_lt_std_roman.svg#Frutiger LT Std Roman") format("svg"), url("../fonts/frutiger_lt_std_roman.woff") format("woff"), url("../fonts/frutiger_lt_std_roman.ttf") format("truetype"); }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto/Roboto-Regular-webfont.eot");
  src: url("../fonts/roboto/Roboto-Regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/Roboto-Regular-webfont.woff") format("woff"), url("../fonts/roboto/Roboto-Regular-webfont.ttf") format("truetype"), url("../fonts/roboto/Roboto-Regular-webfont.svg#robotoregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto/Roboto-Medium-webfont.eot");
  src: url("../fonts/roboto/Roboto-Medium-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/Roboto-Medium-webfont.woff") format("woff"), url("../fonts/roboto/Roboto-Medium-webfont.ttf") format("truetype"), url("../fonts/roboto/Roboto-Medium-webfont.svg#robotomedium") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto/Roboto-Light-webfont.eot");
  src: url("../fonts/roboto/Roboto-Light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/Roboto-Light-webfont.woff") format("woff"), url("../fonts/roboto/Roboto-Light-webfont.ttf") format("truetype"), url("../fonts/roboto/Roboto-Light-webfont.svg#robotolight") format("svg");
  font-weight: 300;
  font-style: normal; }

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

*:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

html, div, body, form, span, ul, li {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline; }

body {
  font-family: "Roboto";
  font-size: 12px;
  line-height: 1.5;
  font-weight: 300;
  color: white;
  background: #222222; }

input[type=text]::-ms-clear,
input[type=password]::-ms-reveal {
  display: none; }

#login input,
#login button,
#login select,
#login textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

#login .input-group {
  position: relative;
  display: table;
  border-collapse: separate;
  margin-bottom: 15px; }

#login .input-group .form-control:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

#login .input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0; }

#login .input-group-addon {
  color: #6f6f6f;
  border: 1px solid #cccccc;
  padding: 8px 12px;
  font-size: 15px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  filter: none;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
  border-style: none;
  border-radius: 0;
  background: rgba(255, 255, 255, 0.15); }

#login .input-group-addon:first-child {
  border-right: 0; }

#login #main-view {
  overflow: hidden;
  min-height: 100vh; }

#login ul {
  list-style: none;
  margin-bottom: 15px; }

#login p, #login li {
  line-height: 1.5; }

#login .panel-default {
  border-color: rgba(255, 255, 255, 0.15); }

#login .panel-default .panel-body {
  background: #404040;
  color: #fff; }

#login .panel-body {
  z-index: 1;
  overflow-y: auto;
  border: solid transparent;
  border-width: 0 1px 1px; }

#login .panel-title {
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  color: inherit;
  font-weight: 900;
  padding: 8px 0 0;
  margin: 0 auto 0;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 80px;
  right: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

#login .app-title {
  font-size: 30px;
  text-align: center;
  color: inherit;
  padding: 0 0 10px;
  margin: 0 auto 0;
  display: block;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif; }

#login-alert {
  background-color: #f04124;
  border-color: #ea2f10;
  color: #ffffff;
  font-size: 12px;
  font-weight: 300;
  padding: 15px;
  margin-bottom: 21px;
  border: 1px solid transparent;
  border-radius: 0; }

#login .form-control {
  display: table-cell;
  padding: 0;
  font-size: 12px;
  color: white;
  background-color: #666666;
  border: 1px solid transparent;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  line-height: 1.42857143;
  background-image: none; }

#login hr {
  border: 0;
  border-top: 1px solid transparent;
  border-color: rgba(255, 255, 255, 0.15);
  box-sizing: content-box;
  height: 0;
  display: block;
  unicode-bidi: isolate;
  -webkit-margin-start: auto;
  overflow: hidden;
  margin: 0; }

#login .text-danger {
  color: #e74c3c;
  margin-top: 8px; }

#login [role="button"] {
  cursor: pointer; }

#login a {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  text-decoration: none;
  color: #50afec;
  background-color: transparent; }

#login .collapse {
  display: none; }

#login h5, #login h6 {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-weight: 400;
  line-height: 1.1;
  display: block !important;
  margin-top: 15px;
  margin-bottom: 15px;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.3); }

#login h5 {
  font-size: 14px;
  color: inherit; }

#login h6 {
  font-size: 13px; }

#login .form-horizontal .form-group {
  margin-left: -15px;
  margin-right: -15px; }

#login #btn-login {
  margin: 30px 0 0 0;
  padding: 20px;
  cursor: pointer;
  color: white;
  background-color: #3a99d9;
  border-color: #2e81b7;
  display: inline-block;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  background-image: none;
  border: 1px solid transparent;
  font-size: 12px;
  line-height: 1.4;
  user-select: none;
  text-shadow: none;
  border-radius: 2px;
  width: 100%; }

#login a[ng-click] {
  cursor: pointer; }

#login ::-webkit-scrollbar {
  width: 12px;
  height: 10px;
  background: rgba(0, 0, 0, 0.8); }

#login .login-title {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  display: block; }

#loginbox {
  font-family: "Roboto", "FontAwesome";
  width: 433px;
  padding: 30px;
  margin: 60px auto;
  line-height: 1; }

#login .user-icon {
  width: 14px;
  height: 14px; }

#loginbox .panel-title {
  position: static;
  text-transform: none;
  font-size: 30px;
  padding: 0 0 10px; }

#loginbox [data-toggle="collapse"] {
  font-weight: bold;
  font-size: 11px; }

#loginbox .well {
  margin-bottom: 0;
  padding: 0 10px;
  min-height: 20px;
  border: 1px solid transparent;
  border-radius: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  background-color: #1c1e22; }

#loginbox #logo {
  width: 250px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  background: url("../../Images/WoodMac_logo_strapline_WHT.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-repeat: no-repeat; }

#loginbox input[type="checkbox"] {
  height: 15px;
  width: 15px;
  position: static;
  visibility: visible;
  vertical-align: middle; }

#loginbox input[type="password"],
#loginbox input[type="text"] {
  height: 40px;
  border-style: none;
  padding-left: 10px; }

#loginbox::-webkit-input-placeholder,
#loginbox:-ms-input-placeholder,
#loginbox:-moz-placeholder,
#loginbox::-moz-placeholder {
  font-weight: bold;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.3); }
